// src/components/Contact.js
import React from 'react';

const Contact = () => (
    <section id="contact" className="position-relative overflow-hidden backGrade py-5 px-3 cropTR ">
        <div className="container">
            <h2 className="text-center mb-4">Contact Me</h2>
            <div className="row">
                <div className="col-lg-12">
                    <div className="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group me-2" role="group" aria-label="First group">
                            <a href="mailto:william.klus@gmail.com" target="_blank" rel="noreferrer">
                                <button type="button" className="btn btn-outline-port">Email</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div><div className='stars'></div>
    </section>
);

export default Contact;
