import React from 'react';




const Jumbotron = () => (
    <section id="home" className="bg-light position-relative overflow-hidden backGrade vh50 pt-5 px-3 cropBR text-center">
        <div className="container d-flex justify-content-center align-items-center h-100">
            <div>
                <h1><span className="text-white">William</span> Klus</h1>
                <h3><span className="text-white">W</span>eb Desginer and <span className="text-white">W</span>eb Developer</h3>
                <div className="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                    <div className="btn-group me-2" role="group" aria-label="First group">
                        <a href="mailto:william.klus@gmail.com" target="_blank" rel="noreferrer">
                            <button type="button" className="btn btn-outline-port">Contact Me</button>
                        </a>
                    </div>
                    <div className="btn-group me-2" role="group" aria-label="Second group">
                        <a href="https://drive.google.com/file/d/1OLpKB02Iy4fG43LM19s0VMFma1xHcj7w/view?usp=sharing" target="_blank" rel="noreferrer">
                            <button type="button" className="btn btn-outline-port">Resume</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div className='stars'></div>
    </section>
);

export default Jumbotron;
