// src/components/CorporateProjects.js
import React, { useState } from 'react';
import BCRED from '../assets/images/BCRED.jpg';
import BEPIF from '../assets/images/BEPIF.jpg';
import BGFLX from '../assets/images/BGFLX.jpg';
import Blackstone from '../assets/images/Blackstone.jpg';
import Breit from '../assets/images/Breit.jpg';
import BXDMS from '../assets/images/BXDMS.jpg';
import BXMIX from '../assets/images/BXMIX.jpg';
import BXMT from '../assets/images/BXMT.jpg';
import BXSL from '../assets/images/BXSL.jpg';
import EH from '../assets/images/EH.jpg';
import PWS from '../assets/images/PWS.jpg';

const CorporateProjects = () => {
    // State to keep track of which card's info is being displayed in the modal
    const [selectedCard, setSelectedCard] = useState(null);

    // Function to handle clicking the "Info" button
    const handleInfoClick = (card) => {
        setSelectedCard(card);
    };

    // Card data array
    const cardData = [
        { image: Blackstone, title: 'Blackstone', link: 'https://blackstone.com' },
        { image: BCRED, title: 'BCRED', link: 'https://bcred.com' },
        { image: BEPIF, title: 'BEPIF', link: 'https://bepif.com' },
        { image: BGFLX, title: 'BGFLX', link: 'https://bgflx.blackstone.com' },
        { image: Breit, title: 'Breit', link: 'https://breit.com' },
        { image: BXDMS, title: 'BXDMS', link: 'https://bxdms.blackstone.com' },
        { image: BXMIX, title: 'BXMIX', link: 'https://bxmix.blackstone.com' },
        { image: BXMT, title: 'BXMT', link: 'https://www.blackstonemortgagetrust.com/' },
        { image: BXSL, title: 'BXSL', link: 'https://bxsl.com' },
        { image: EH, title: 'Equity Healthcare', link: 'https://www.equityhealthcare.com/' },
        { image: PWS, title: 'PWS', link: 'https://pws.blackstone.com' }
    ];

    return (
        <section id="corporate-projects" className="py-5 px-3 cropR bgLightGrey">
            <div className="container">
                <h2 className="text-center mb-4">Past Corporate Projects</h2>
                <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-4 justify-content-center">
                    {cardData.map((card, index) => (
                        <div className="col d-flex align-items-stretch" key={index}>
                            <div className="card">
                                <img src={card.image} className="card-img-top" alt={card.title} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">{card.title}</h5>
                                    <div className="mt-auto">
                                        <a href={card.link} target="_blank" rel="noopener noreferrer" className="btn btn-outline-port me-2">Visit</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default CorporateProjects;
