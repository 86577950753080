import React from 'react';
import "../assets/css/lava.css";

const NotFoundPage = () => {
    return (
        <div className="four0four">

            <section class="content">
                <div class="container d-flex justify-content-center align-items-center h-100 text-center">
                    <div>
                        <h1>
                            404
                        </h1>
                        <h3>
                            The page you're looking for doesn't exist. Please check the URL and try again.
                        </h3>
                        <div class="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group me-2" role="group" aria-label="Second group">
                                <a href="/" rel="noreferrer">
                                    <button type="button" class="btn btn-outline-port">Go back to homepage</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="lamp">
                    <div class="lava">
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob"></div>
                        <div class="blob top"></div>
                        <div class="blob bottom"></div>

                    </div>
                </div>

                <svg>
                    <defs>
                        <filter id="goo">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                            <feBlend in="SourceGraphic" in2="goo" />
                        </filter>
                    </defs>
                </svg>



            </section>
        </div>

    );
};

export default NotFoundPage;