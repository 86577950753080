// src/components/AboutMe.js
import React from 'react';

const AboutMe = () => (
    <section id="about-me" className="py-5 px-3">
        <div className="container">
            <h2 className="text-center mb-4">About Me</h2>
            <div className="row">
                <div className="col-lg-12">
                    <p>I graduated with a <strong>Bachelor of Science in Digital Media/Graphic Design</strong>. My journey into the professional world began with an internship at <strong>Blackstone</strong> in their ITS department.
                        I then transitioned to the Publishing department focusing on graphic design and photo and video editing. From there i was asked if i would like to move to the moved to the website team, where I've spent over a decade contributing as both a web designer and developer.</p>

                    <p>With more than 15 years of experience in the web development and graphic design field, I've honed my skills in both design and coding, making me a versatile asset in digital projects.</p>
                </div>
            </div>
        </div>
    </section>
);

export default AboutMe;
