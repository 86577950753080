// src/components/PersonalProjects.js
import React, { useState } from 'react';
import Hazedoriginals from '../assets/images/Hazedoriginals.jpg';
import Parksdemo from '../assets/images/Parks.jpg';
import Plex from '../assets/images/Plex.jpg';
import Apex from '../assets/images/Apex.jpg';
import Twitch from '../assets/images/Twitch.jpg';

const PersonalProjects = () => {
    // State to keep track of which card's info is being displayed in the modal
    const [selectedCard, setSelectedCard] = useState(null);

    // Function to handle clicking the "Info" button
    const handleInfoClick = (card) => {
        setSelectedCard(card);
    };

    // Card data array
    const cardData = [
        { image: Hazedoriginals, title: 'HazedOriginals', link: 'https://hazedoriginals.com', description: 'This is a website made for my custom sneaker. This simple site was built in Wordpress' },
        { image: Parksdemo, title: 'Park Inspections', link: 'https://parksdemo.hazedoriginals.com', description: 'This site demo was built in React. Made for a friend to show the potential of React' },
        { image: Plex, title: 'Plex List Request', link: 'https://plexlist.hazedoriginals.com', description: 'This platform serves as a centralized request system for my media server, accessible by friends and family. It streamlines the process of adding new movies to the server by allowing users to search for and add films they want to see. When a movie is added to the shared list utilizing Firebase, I receive an email notification prompting me to obtain the film and add it to the media server (through legal means of course). Users who are logged in via Google will also be notified by email once the requested movie is available on the server. This setup creates an organized and efficient way to manage movie requests, ensuring everyone stays informed. ' },
        { image: Apex, title: 'Apex Stream Tracker', link: 'https://apextracker.hazedoriginals.com/', description: "Nearly complete, this platform enhances the streaming experience for Apex Legends players by allowing them to easily integrate their game statistics into a live stream overlay. Users have the option to either log in using their Google account or by creating an account with their email and password. Upon logging in, users can search for a specific player's stats through an API call. A list of these stats then appears in a checkbox format, allowing users to select the ones they want to display on their overlay. Once they apply the changes, their preferences are stored in Firebase and linked to a unique User ID (UID). Users can then navigate to a special stats page—identified by their UID in the URL—to view their stats, displayed within a stylish graphic overlay. This URL can be easily integrated into any streaming software to enrich the viewer's experience." },
        { image: Twitch, title: 'Twitch Chat Overhaul', link: 'https://chrome.google.com/webstore/detail/twitch-chat-overhaul/eiehpdffmelfdfjpmicbacjancjlojho', description: `I developed this Google Chrome extension to enhance the readability of Twitch's chat box. Currently boasting over 1,000 users, the extension has gained notable attention, even being featured in an article titled "8 Must-Have Twitch Extensions to Fuel Every Gamer's Livestreams" on Filmora's website. You can read more about it <a href="https://filmora.wondershare.com/live-streaming/twitch-extensions.html" target="_blank">here</a>. The extension offers a range of features that allow users to customize the chat interface, making it easier to follow conversations while streaming.` },
    ];

    return (
        <section id="personal-projects" className="py-5 px-3">
            <div className="container">
                <h2 className="text-center mb-4">Personal Projects</h2>
                <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 g-4 justify-content-center">
                    {cardData.map((card, index) => (
                        <div className="col d-flex align-items-stretch" key={index}>
                            <div className="card">
                                <img src={card.image} className="card-img-top" alt={card.title} />
                                <div className="card-body d-flex flex-column">
                                    <h5 className="card-title">{card.title}</h5>
                                    <div className="mt-auto">
                                        <a href={card.link} target="_blank" rel="noopener noreferrer" className="btn btn-outline-port me-2">Visit</a>
                                        <button onClick={() => handleInfoClick(card)} className="btn btn-outline-info" data-bs-toggle="modal" data-bs-target="#infoModal">Info</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Bootstrap Modal */}
                <div className="modal fade" id="infoModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content rounded-0">
                            <div className="modal-header backGrade cropBL rounded-0">
                                <h5 className="modal-title" id="exampleModalLabel">{selectedCard?.title}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body" dangerouslySetInnerHTML={{ __html: selectedCard?.description || "No description available" }}>
                            </div>
                            <div className="modal-footer backGrade cropTL rounded-0">
                                <button type="button" className="btn btn-outline-port" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PersonalProjects;