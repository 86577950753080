// src/components/Navigation.js
import React, { useState, useEffect } from 'react';

export default function Navigation() {

    // Scroll to section function
    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const [navBackground, setNavBackground] = useState('transparent');
    const [navClass, setNavClass] = useState('blurred');

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50 * window.innerHeight / 100) {
                setNavClass('backGrade');
            } else {
                setNavClass('blurred');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // State to manage navbar collapse for mobile screens
    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <>
            {/* Navigation */}
            <nav className={`navbar fixed-top navbar-expand-lg navbar-light navbar-nav-scroll ${navClass}`}
                style={{ backgroundColor: navBackground }}>

                <div className="container-fluid">
                    <div className="navbar-brand">
                        <span className='colorWhite'>W</span>KLUS
                    </div>

                    {/* Toggler Button for small screens */}
                    <button className="navbar-toggler"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    {/* Navigation Links */}
                    <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`}
                        id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <button className="nav-link btn btn-link"
                                    onClick={() => scrollToSection('home')}>Home</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link btn btn-link"
                                    onClick={() => scrollToSection('about-me')}>About Me</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link btn btn-link"
                                    onClick={() => scrollToSection('corporate-projects')}>Corporate Projects</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link btn btn-link"
                                    onClick={() => scrollToSection('personal-projects')}>Personal Projects</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link btn btn-link"
                                    onClick={() => scrollToSection('contact')}>Contact</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
