import React, { useEffect } from 'react';
import "bootstrap/dist/js/bootstrap.bundle.min";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import Navigation from './components/Navigation';
import Jumbotron from './components/Jumbotron';
import AboutMe from './components/AboutMe';
import CorporateProjects from './components/CorporateProjects';
import PersonalProjects from './components/PersonalProjects';
import Contact from './components/Contact';
import "./assets/css/main.css";


const App = () => {

  // Trigger the toast when the component mounts
  useEffect(() => {
    const toastEl = document.getElementById('my-toast');
    const toast = new bootstrap.Toast(toastEl);
    toast.show();
  }, []);

  return (
    <div>

      <Navigation />
      <Jumbotron />
      <AboutMe />
      <CorporateProjects />
      <PersonalProjects />
      <Contact />

      {/* Bootstrap 5 Toast */}
      <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 999999 }}>
        <div id="my-toast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
          <div className="toast-header">
            <strong className="me-auto">Please Take Note</strong>
            <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div className="toast-body">
            This page is still WIP. Started dev 9/26/23
          </div>
        </div>
      </div>
    </div>

  )
};

export default App;
